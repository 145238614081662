import { IState } from '.';
import { NetworkDeviceClusterMode } from '@core/constants';

export enum MerakiTypeEnum {
  MX = 'meraki_mx',
  MS = 'meraki_ms',
  MR = 'meraki_mr',
  MG = 'meraki_mg',
}
export type TMerakiType = MerakiTypeEnum.MX | MerakiTypeEnum.MS | MerakiTypeEnum.MR | MerakiTypeEnum.MG;

export interface ITinyNetworkDevice {
  id: string;
  code: string;
  type?: string;
  number?: number;
}

export interface INetworkDevice extends ITinyNetworkDevice {
  // TODO-Type as IEntity
  entity: any;
  is_active: boolean;
  // TODO-Type as IConfigTemplate
  config_template: any;
  // TODO-Type as IEquipment
  equipment: any;
  // TODO-Type as IEquipment
  current_equipment?: any;
  // TODO-Type as IOperatorLine
  operator_line: any;
  printing_label: string;
  monitoring_label: string;
  is_up: boolean;
  state: IState;
  line_count: number;
  last_config_attach_id: number;
  last_dump_attach_id: number;
}

export interface IMerakiNetworkDevice extends INetworkDevice {
  name: string;
  internal_ref: any;
  dhcp: boolean;
  vlan: number;
  address: string;
  mask: string;
  gateway_address: string;
  dns_primary: string;
  dns_secondary: string;
}

export interface IMerakiMSNetworkDevice extends IMerakiNetworkDevice {
  meraki_switch_profile: any;
}

export interface IMerakiMXNetworkDevice extends IMerakiNetworkDevice {
  cluster_mode: NetworkDeviceClusterMode;
  spare_name: string;
  // TODO-Type as IEquipment
  secondary_equipment: any;
  minor_dhcp: boolean;
  minor_vlan: number;
  minor_address: string;
  minor_mask: string;
  minor_gateway_address: string;
  minor_dns_primary: string;
  minor_dns_secondary: string;
}
