import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { ConfigService } from '@core/config/config.service';

import {
  IBuildingDataPayload,
  IEligibilityConfiguration,
  IEligibilityRequestDataPayload
} from '@views/eligibility/eligibility.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiLLPService {

  constructor(
    private api: ApiService,
    private config: ConfigService
  ) { }

  private _call(method: string, path: string, data?: any, disableError?: boolean): Promise<any> {
    return this.api.call(method, this.config.apis.llp + path, data, disableError);
  }

  public status() {
    return this._call('GET', '/api/is_up');
  }

  /**************Eligibility*************/
  public checkFeasibilityByPhoneNumber(data) {
    return this._call('POST', '/api/check-feasibility-by-phone-number', data);
  }

  public checkFeasibilityByAddress(data) {
    return this._call('POST', '/api/check-feasibility-by-address', data);
  }

  public checkFeasibilityByGPS(data) {
    return this._call('POST', '/api/check-feasibility-by-gps', data);
  }

  public getCopperEligibilityFromNdi(ndi, skipElibilityData: boolean, normaliseResults: boolean) {
    const payload = {
      skip_eligibility_data: skipElibilityData,
      normalise_results: normaliseResults
    };
    return this._call('POST', '/api/get-copper-eligibility-from-ndi/' + ndi + '/', payload , true);
  }

  public getCopperAddressesFromAddress(data) {
    return this._call('POST', '/api/get-copper-addresses-from-address/', data , true);
  }

  public getCopperAddressesFromGps(data) {
    return this._call('POST', '/api/get-copper-addresses-from-gps/', data , true);
  }

  public getFttoEligibilityFromGps(data) {
    return this._call('POST', '/api/get-ftto-eligibility-from-gps/', data , true);
  }

  public getFtteEligibilityFromGps(data) {
    return this._call('POST', '/api/get-ftte-eligibility-from-gps/', data , true);
  }

  public getGoogleGeocode(address: string) {
    return this._call('GET', '/api/get-google-geocode/?full_address=' + address);
  }

  public getEligibilityData(data: IEligibilityRequestDataPayload) {
    return this._call('POST', '/api/get-eligibility-data/', data);
  }

  public getEligibilityResult(data: any) {
    return this._call('POST', '/api/get-eligibility-result/', data);
  }

  public getEligibilityBulkResult() {
    return this.config.apis.llp + '/api/get-eligibility-bulk-result/';
  }

  public getBuildingData(data: IBuildingDataPayload) {
    return this._call('GET', '/api/get-building-data/' + this.api.buildQueryString(data) );
  }

  /*******ELIGIBILITY CONFIGURATION******/

  public getEligibilityConfigurations(): Promise<IEligibilityConfiguration[]> {
    return this._call('GET', '/api/eligibility-configurations/');
  }

  public updateConfigurations(configurations: IEligibilityConfiguration[]): Promise<IEligibilityConfiguration[]> {
    return this._call('POST', '/api/eligibility-configurations/update/', { configurations });
  }

  /*****************ERDV*****************/
  public getAvailableAppointmentsDateRange(data) {
    return this._call('POST', '/api/erdv-available-appointments/', data, true);
  }

  public createAppointment(data) {
    return this._call('POST', '/api/erdv-create-appointment/', data, true);
  }

  public cancelAppointment(data) {
    return this._call('POST', '/api/erdv-cancel-appointment/', data, true);
  }

  public lookupAppointment(data) {
    return this._call('POST', '/api/erdv-lookup-appointment/', data, true);
  }

  public updateAppointment(data) {
    return this._call('POST', '/api/erdv-update-appointment/', data, true);
  }
}
