import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FormMode } from '@core/constants';
import { ILogisticSite } from '@core/interfaces/logistics-sites';

@Component({
  selector: 'app-carrier-accounts-modal',
  templateUrl: './carrier-accounts-modal.component.html',
})
export class CarrierAccountsModalComponent {
  @Input() public contentType: 'multiSelectList' | 'list' = 'list';
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public pk: any;
  @Input() public defaults: any;
  @Input() public mode: FormMode = 'normal';

  public localDisabledButtons: any;

  constructor(public modal: NgbActiveModal) { }

  public validateSelection(selectedItems: Record<string, ILogisticSite>): void {
    this.modal.close(Object.values(selectedItems));
  }
}
