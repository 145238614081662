import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-generic-field',
  templateUrl: './generic-field.component.html',

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: GenericFieldComponent,
    multi: true
  }]
})
export class GenericFieldComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;
  @Input() public disableRouterLink: boolean;

  @Input() public linkLabel: string;
  @Input() public selectLabel: string;
  @Input() public pk: any;
  @Input() public path: string;

  @Output() public openModalEvent = new EventEmitter();
  @Output() public resetEvent = new EventEmitter();

  public detail: any;
  public onChangeCb: any;
  public ngbModal: NgbModal;

  private disableRouterLinkBackup: boolean;

  constructor(
    public injector: Injector
  ) {
    this.ngbModal = injector.get(NgbModal);
  }

  public ngOnInit(): void {
    this.disableRouterLinkBackup = this.disableRouterLink;
  }

  public openModal(): void {
    this.openModalEvent.emit();
  }

  public choose(modalComponent): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = {...this.disabledColumns};
    const localDisabledButtons = {create: true, ...this.disabledButtons};
    const localFilters = {limit: 10, ...this.filters};

    const modal = this.ngbModal.open(modalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = localFilters;

    modal.result.then(
      res => {
        this.detail = res;
        this.onChangeCb(this.detail);
      },
      () => {}
    );
  }

  public reset(): void {
    this.resetEvent.emit();
  }

  public clearValue(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }

  public checkCtrlKey(event): void {
    this.disableRouterLink = (event.ctrlKey || event.metaKey || event.which === 2) ? false : this.disableRouterLinkBackup;
  }

  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }

}
