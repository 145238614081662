import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { times } from 'underscore';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { EQP_LOCATIONS, EQP_OWNERS, EQP_OWNERS_ORDERED_OPTIONS, EquipmentLocationEnum, ZONES_OPTIONS } from '@core/constants';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IGenericApi, IGenericListOptions } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { EquipmentsService } from './equipments.service';
import { EquipmentsDetailNoSerialNumberModalComponent } from './equipments-detail-no-serial-number-modal.component';

@Component({
  selector: 'app-equipments-detail',
  templateUrl: './equipments-detail.component.html',
  styles: []
})
export class EquipmentsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public disabledButtonCreateAdd: boolean;
  @Input() public disableCreateRedirection: boolean;

  public readonly EquipmentLocationEnum = EquipmentLocationEnum;

  private defaultBreadcrumbsData = [{label: 'Équipements', routerLink: '/equipments/list'}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'equipments';
  public reservedForList: IGenericListOptions;
  private api: IGenericApi;

  public locationOptions = EQP_LOCATIONS;
  public ownerOptions = EQP_OWNERS_ORDERED_OPTIONS;
  public macPattern = /^([0-9A-Fa-f]{2}(:|-|\s|)){5}[0-9A-Fa-f]{2}$/;
  public counts = {
    status: null,
    updated: false,
    checked_eqp: 0,
    available_eqp: 0,
    ordered_eqp: 0,
    current_equipment_in_order: 0, // number of equip for the current order
  };

  public commentsCount: number;
  public equipementModelFilters: any;
  public prefix: string;
  public recordList: any;
  public zones: string[];
  private signalSubscriptions: Subscription[] = [];


  constructor(
    private apiShiva: ApiShivaService,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private equipmentsService: EquipmentsService,
    private router: Router,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {location: EquipmentLocationEnum.Stock};
    // Api used for fetch, update and create
    this.api = this.apiShiva.equipments as IGenericApi;

    // This enable the live update (websocket)
    this.liveUpdateChannel = 'equipment';
  }


  public ngOnInit(): void {
    super.ngOnInit();
    this._initSubscriptions();
    this.reservedForList = {
      filters: {
        is_customer: true,
        parent__isnull: true
      },
      disabledButtons: {
        type: true
      }
    };
    this.zones = ZONES_OPTIONS;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public onChangeLocation(location: string) {
    this.detail.entity = null;
    this.detail.zone = location !== EquipmentLocationEnum.Stock ? '' : this.detail.zone;
  }

  public removePrefix() {
    this.detail.serial_number = this.equipmentsService.snRemovePrefix(this.detail.serial_number, this.prefix);
    this.prefix = null;
  }

  public addNoSerialNumberEquipmentModal() {
    this.loading = true;
    if (this.detail.id || !this.hasPermissions('Wira:EqpCanManageNoSN')) {
      return;
    }
    const modal = this.ngbModal.open(EquipmentsDetailNoSerialNumberModalComponent, {backdrop: 'static', size: 'md'});
    modal.result.then((quantity: number) => {
      this._generateRandomSN(quantity);
    }, () => {}).finally(() => {
      this.loading = false;
    });
  }

  public onProviderOrderUpdate() {
    this._updateEquipmentModelFilters();
    this.counts.updated = false;
    this._fetchAvailableEquipmentCount();

    if (!this.detail?.provider_order) {
      return;
    }

    this.detail.owner = this.detail?.provider_order?.buying_company?.code;
    // Checking only the first quote to get the quote entity
    const providerOrderQuotes = this.detail.provider_order.quotes || [];
    const firstQuote = providerOrderQuotes[0] || {};
    this.detail.reserved_for = firstQuote.entity || null;
  }

  public onEquipmentModelUpdate() {
    this.counts.updated = false;
    if (!this.detail.model || !this.detail.provider_order) {
      return;
    }
    this._fetchAvailableEquipmentCount();

    // We fetch one of the accounting eqp of this provider order and this equipment model to get it's unit price
    const filters = {
      limit: 1,
      provider_order__order_number: this.detail.provider_order.order_number,
      equipment_model_mapping__equipment_model__id: this.detail.model.id
    };

    this.apiShiva.accounting_equipments.list(filters)
      .then((res) => {
        if (res['count'] > 0) {
          this.detail.price_untaxed = res['results'][0].unit_price;
          this.counts.current_equipment_in_order = res['results'][0].current_equipment_in_order;
          this.counts.checked_eqp = this.counts.current_equipment_in_order;
          // change the color of the displayed message
          this._changeCountStatus();
        }
      }).catch((err) => {
        this.toastr.error(`Erreur lors de la récupération du prix unitaire de l'équipement comptable.`);
      });
  }

  public onSerialNumberUpdate() {
    // Check if serial numbers are given by the user, and if there are, count them
    let contentTextarea = this.detail.serial_number.split(/\r?\n/);
    contentTextarea = contentTextarea.filter(serialNumber => serialNumber.length >= 1); // ignore serialNumber = ""
    this.counts.checked_eqp = (this.detail.serial_number ? contentTextarea.length : 0 ) + this.counts.current_equipment_in_order;
    this._changeCountStatus();
  }

  /**
   * initialise the various signal subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
  private _initSubscriptions() {
    const commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.signalSubscriptions.push(commentsCountSubscription);
  }

  private _changeCountStatus() {
    if (this.counts.checked_eqp > this.counts.ordered_eqp) {
      this.counts.status = 'danger';
    } else if (this.counts.checked_eqp < this.counts.ordered_eqp) {
      this.counts.status = 'warning';
    } else if (this.counts.checked_eqp === this.counts.ordered_eqp) {
      this.counts.status = 'success';
    }
  }

  private _fetchAvailableEquipmentCount() {
    if (this.detail && this.detail.provider_order && this.detail.model) {
      this.apiShiva.provider_orders.available_equipments(this.detail.provider_order.id, this.detail.model.id).then(res => {
        this.counts.updated = true;
        this.counts.ordered_eqp = res['ordered_count'];
      }).catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'MISSING_MODEL_ID') {
            this.toastr.error(`Impossible d'effectuer cette action car l'identifiant du modèle n'a pas été reçu par le serveur.`);
            return;
          }
        }
        Promise.reject(err);
      });
    }
  }

  private _fetchRecords() {
    if (!this.detail.code) {
      return;
    }

    this.apiShiva.equipment_records.list({equipment__code: this.detail.code})
      .then(res => {
        // Generating the comments based on the status
        this.recordList = res['results'].map((item) => {
          switch (item.status.toLocaleLowerCase()) {
            case 'added':
              item.text = 'Equipement créé.';
              break;
            case 'moved_inventory':
              item.text = 'Retour au stock.';
              break;
            case 'moved_inventory_while_inventory':
              item.text = 'Retour au stock dans le cadre d\'un inventaire';
              break;
            case 'pending':
              item.text = 'Mise en attente durant l\'inventaire';
              break;
            case 'leave_shop':
              item.text = 'Retrait du magasin';
              if (item.entity) {
                item.text += ' <a href="/#/entities/detail/' + item.entity.code + '">' + item.entity.code + '</a> ' +
                             '(' + item.entity.name + (item.entity.customer_ref ? ' / ' + item.entity.customer_ref : '') + ').';
              } else {
                item.text += '.';
              }
              break;
            case 'moved_shop':
              item.text = 'Affectation au magasin';
              if (item.entity) {
                item.text += ' <a href="/#/entities/detail/' + item.entity.code + '">' + item.entity.code + '</a> ' +
                             '(' + item.entity.name + (item.entity.customer_ref ? ' / ' + item.entity.customer_ref : '') + ').';
              } else {
                item.text += '.';
              }
              break;
            case 'reserved':
              if (item.entity) {
                item.text = 'Réservation pour le magasin';
                item.text += ' <a href="/#/entities/detail/' + item.entity.code + '">' + item.entity.code + '</a> ' +
                             '(' + item.entity.name + (item.entity.customer_ref ? ' / ' + item.entity.customer_ref : '') + ').';
              } else {
                item.text = 'Annulation de la réservation.';
              }
              break;
            case 'changed': {
              const owners = item.comment?.split('|') || [];
              owners[0] = owners[0] ? (EQP_OWNERS[owners[0]] || owners[0]) : 'Aucun';
              owners[1] = owners[1] ? (EQP_OWNERS[owners[1]] || owners[1]) : 'Aucun';
              item.text = 'Changement de propriétaire de l\'équipement : ' + owners[0] + ' -> ' + owners[1] + '.';
              break;
            }
            case 'deleted':
              item.text = 'Equipement supprimé.';
              break;
            case 'invoiced':
              item.text = 'Equipement facturé.';
              break;
            case 'zone_updated': {
              const zones: string[] = item.comment?.split('|') || [];
              zones[0] = zones[0] || 'Vide';
              zones[1] = zones[1] || 'Vide';
              item.text = 'Changement de zone : ' + zones[0] + ' -> ' + zones[1] + '.';
              break;
            }
            case 'collecting': {
              item.text = 'A prélever.';
              break;
            }
            default:
              item.text = item.comment;
              break;
          }
          return item;
        });
      });
  }

  private _updateEquipmentModelFilters() {
    this.equipementModelFilters = {
      equipment_model_mappings__accounting_equipments__provider_order__id: this.detail.provider_order?.id,
    };
  }

  private _generateRandomSN(quantity: number) {
    const hexTimestamp = moment().valueOf().toString(16);
    const newSNArray = times(quantity, (i) => `WCMNOSN-${hexTimestamp}${i}`);

    if (this.detail.serial_number) {
      this.detail.serial_number +=  '\n' + newSNArray.join('\n');
    } else {
      this.detail.serial_number = newSNArray.join('\n');
    }
  }

  public onEntityUpdate(newVal) {
    // This function is trigerred only when detail.entity is changed by the user directly from the field
    // and not when the detail.entity is set to null when detail.location changed
    this.detail.entity = newVal;
    if (newVal) {
      this.detail.location = EquipmentLocationEnum.Shop;
    } else if (newVal === null) {
      // user has deliberately cleared the value for 'entity', set the location to 'stock'
      this.detail.location = EquipmentLocationEnum.Stock;
    }
  }

  public save(redirectToCreation?: boolean) {
    if (!(this.detailForm && this.detailForm.valid) || this.loading) {
      return;
    }
    this.loading = true;
    let promise;

    this.detail.serial_number = this.detail.serial_number.replaceAll('\n\n', '\n');

    if (this.detail.mac_address) {
      this.detail.mac_address = this.equipmentsService.cleanMacAddress(this.detail.mac_address);
    }

    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        this.toastr.success(`${res.length > 1 ? 'Élements créés' : 'Élement créé'}  avec succès.`);
        this.detail.serial_number = '';
      }
      if (!this.detail.code && redirectToCreation) {
        this.detail.zone = '';
        this.router.navigateByUrl('/equipments/detail/');
        return;
      }
      if (!this.detail.code) {
        // it was a creation
        if (res.length > 1 && !this.disableCreateRedirection) {
          // this prevent redirecting after a creation in a modal
          this.router.navigateByUrl('/equipments/list');
          return;
        } else {
          res = res[0];
        }
        this.pk = res.code;
        this.signalsService.broadcast('equipments:create', res.code);
        this._initTabs(res);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this.mode = 'normal';

      // fetching the records
      this._fetchRecords();
      this._updateEquipmentModelFilters();
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    }).catch((err) => {
      this._handleSaveError(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  private _handleSaveError(err: Error) {
    if (err instanceof WaycomHttpErrorResponse) {
      if (err.detail === 'DUPLICATE_SERIAL_NUMBERS') {
        let errMsg = 'Erreur lors de la création des équipements. Aucun équipement n\'a été créé.<br>';
        errMsg += 'Les numéros de série suivants existent déjà :';
        errMsg += '<ul>';
        err.context.duplicate_sn.forEach((sn) => {
          errMsg += `<li>${sn}</li>`;
        });
        errMsg += '</ul>';
        this.wcmModalsService.alert('Numéro de série déjà existant', errMsg);
        return;
      } else if (err.detail === 'DUPLICATE') {
        const errMsg = 'Un equipment avec le même numéro de série existe déjà.';
        this.wcmModalsService.alert('Numéro de série déjà existant', errMsg);
        return;
      } else if (err.getFirstErrorMessage() === 'ZONE_ONLY_FOR_LOCATION_STOCK') {
        this.toastr.error(`Impossible de donner un zonage si l'équipement n'est pas en stock.`);
        return;
      }
    }

    // error not handled locally, bubble up to generic handler
    Promise.reject(err);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);

        // fetching the records
        this._fetchRecords();
        this._updateEquipmentModelFilters();
      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  private _initTabs(detail) {
    // If any tab filter must be initialized, it's done here
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail && this.detail.entity && this.detail.entity.code) {
      this.breadcrumbsData.push({
        label: this.detail.entity.name,
        routerLink: '/entities/detail/' + this.detail.entity.code,
        after: '>'}
      );
    }

    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/equipments/detail/${this.detail.code}`,
        active: true
      });
    }
  }
}


