import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { WOI_GENERIC_LINE_ACTIONS } from '@core/constants';
import { IOperatorLine } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';


interface IGenericLineMetadata {
  operator_line_id: number;
  operator_line_code: string;
  operator_line_label: string;
  action?: IAction;
}

interface IAction {
  value: string;
  label: string;
  ol_states: Array<string>;
}

@Component({
  selector: 'app-generic-line-metadata',
  templateUrl: './generic-line-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class GenericLineMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('f', {static: true}) public f: NgForm;
  @Input() public mode = 'normal';
  @Input() public woi;
  @Input() public woiRefreshDetail: () => void;

  public filteredActions = WOI_GENERIC_LINE_ACTIONS;
  public operatorLine: IOperatorLine;
  // TODO-Type as IEntity
  public entity: any;
  public metadataName = 'generic-line';
  public locationStr: string;
  public displayOlWarnColNode: boolean;
  public displayOlWarnVlan: boolean;

  private metadataBackup: IGenericLineMetadata;
  private signalSubscriptions: Subscription[] = [];


  constructor(
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
  ) { }

  public ngOnInit(): void {
    const entityChangeSignalHandler = this.signalsService.subscribe('woi-entity-change', entityObj => {
      this.entity = entityObj && entityObj.code ? entityObj : {};
      this._createLocationString();
      // if the entity of the WOI changes, we clear the OL to force the user to re-select
      this.operatorLine = null;
      this.operatorLineUpdated();
    });
    this.signalSubscriptions.push(entityChangeSignalHandler);

    const woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = {
        ...this.woi.metadata,
        ...this.metadataBackup,
      };
    });
    this.signalSubscriptions.push(woiEditionCancelledSignalHandler);

    const genericLineRefreshSignalHandler = this.signalsService.subscribe('generic-line-metadata-refresh', () => {
      this._init();
    });
    this.signalSubscriptions.push(genericLineRefreshSignalHandler);

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup = {...this.woi.metadata};
    }

    // Set an action by default when we swich to edition mode for workflow managment
    if (currentMode === 'edition') {
      this.woi.metadata.action = this.woi.metadata.action || this.filteredActions[0];
      this._getActionsForOL(this.operatorLine);
    } else if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
  }

  public operatorLineUpdated() {
    this.displayOlWarnColNode = false;
    this.displayOlWarnVlan = false;

    this.woi.metadata.operator_line_code = this.operatorLine && this.operatorLine.code;
    this.woi.metadata.operator_line_label = this.operatorLine && this.operatorLine.label;

    this.filteredActions = this._getActionsForOL(this.operatorLine);

    if (this.operatorLine) {
      this.woi.metadata.action = this.filteredActions[0];
    }else {
      this.woi.metadata.action = null;
    }
  }

  public compareActionFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
  }

  private _init() {
    const wo = this.woi.work_order || {};
    this.entity = wo.entity || {};
    this._createLocationString();
    this._initOperatorLine();
  }

  private _createLocationString() {
    const location = this.woi.location;
    if (location) {
      this.locationStr = `${location.address} - ${location.zipcode} ${location.city}`;
    } else {
      this.locationStr = '';
    }
  }

  private _initOperatorLine() {
    const operatorLineCode = this.woi?.metadata?.operator_line_code;
    if (operatorLineCode) {
      this._fetchOperatorLine(operatorLineCode);
    } else {
      this.operatorLine = null;
    }
  }

  private _fetchOperatorLine(operatorLineCode: string) {
    this.apiProvitool.operator_lines.detail(operatorLineCode).then(
      res => {
        this.operatorLine = res;
        // OL has changed, need to re-filter the available actions
        this.filteredActions = this._getActionsForOL(this.operatorLine);
        if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
        this._checkOLInfo();
      },
      err => {
        console.error(err);
        this.toastr.error('Echec de récupération du lien opérateur.');
      }
    );
  }

  private _getActionsForOL(ol) {
    if (!ol || !ol.state) {
      return WOI_GENERIC_LINE_ACTIONS;
    }

    const isOldActionValueKept = ol.state?.name !== 'delivered' ? true : false;

    const availableActions = WOI_GENERIC_LINE_ACTIONS.filter((action) =>  {
      return action.ol_states.indexOf(ol.state.name) > -1;
    });

    if (this.woi.metadata.action && Object.keys(this.woi.metadata.action).length) {
      const isCurrentActionInAvailableList = availableActions.find(i => i.value === this.woi.metadata.action.value) ? true : false;
      if (!isCurrentActionInAvailableList && isOldActionValueKept) {
        availableActions.push(this.woi.metadata.action);
      }
    }
    return availableActions;
  }

  private _checkOLInfo() {
    const offerHasColNode = this.operatorLine.offer?.has_collection_nodes;
    this.displayOlWarnColNode = (this.operatorLine && !this.operatorLine.collection_node && offerHasColNode) ? true : false;
    this.displayOlWarnVlan = (this.operatorLine && !this.operatorLine.vlan && offerHasColNode) ? true : false;
  }

}
