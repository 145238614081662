import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ICarrierAccount, ILogisticSite } from '@core/interfaces/logistics-sites';
import { IGenericListOptions } from '@core/interfaces';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { CarrierAccountsModalComponent } from '@views/carrier-accounts/carrier-accounts-modal.component';

@Component({
  selector: 'app-logistics-sites-detail',
  templateUrl: './logistics-sites-detail.component.html',
})
export class LogisticsSitesDetailComponent extends GenericDetailComponent implements OnInit {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public hideTabs = false;
  private defaultBreadcrumbsData = [{label: 'Sites logistiques', routerLink: '/logistics-sites/list'}];
  private api: ApiShivaService['logistics_sites'];
  public readonly viewName = 'logisticsSites';

  public detail: ILogisticSite;
  public carrierAccountsList: IGenericListOptions;

  constructor(
    public readonly injector: Injector,
    private readonly ngbModal: NgbModal,
    private readonly apiShiva: ApiShivaService,
    private readonly wcmModalsService: WcmModalsService,
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {/*...*/} as ILogisticSite;
    // Api used for fetch, update and create
    this.api = this.apiShiva.logistics_sites;
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;
    let promise: Promise<ILogisticSite>;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res: ILogisticSite) => {
      if (!this.detail.id) {
        // it was a creation
        this.signalsService.broadcast('logistics-sites:create', res.code);
      }
      this.detail = res;
      this._updateBreadcrumbs();
      this._initTabs(res);
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.detailSaved.emit(this.detail);
    })
      .catch((err) => Promise.reject(err))
      .finally(() => {
        this.loading = false;
        this.signalsService.broadcast('model-history-list-refresh');
      });
  }

  public addCarrierAccounts(): void {
    this.loading = true;

    const modal = this.ngbModal.open(CarrierAccountsModalComponent, {backdrop: 'static', size: 'lg'});
    modal.componentInstance.disabledColumns = {
      selection: false,
    };
    modal.componentInstance.disabledButtons = {
      create: true,
      select: true,
    };
    modal.componentInstance.filters = {
      logistics_site__code__isnull: true,
    };
    modal.componentInstance.contentType = 'multiSelectList';

    modal.result.then(
      (selected: ICarrierAccount[]) => {
        const carrierAccountCodes: string[] = selected.map((carrierAccount: ICarrierAccount) => carrierAccount.code);

        this.apiShiva.logistics_sites.addCarrierAccounts(this.detail.code, carrierAccountCodes)
          .then(() => {
            this.signalsService.broadcast('carrier-accounts-list-refresh');
            this.signalsService.broadcast('model-history-list-refresh');
          })
          .catch((err: unknown) => {
            console.error(err);
            this.toastr.error(`Echec de l'ajout des comptes transporteurs. Veuillez réessayer à nouveau.`);
          })
          .finally(() => this.loading = false);
      },
      () => {}
    );
  }

  public removeCarrierAccount(carrierAccount: ICarrierAccount): void {
    const msgTitle = `Suppression d'un compte transporteur`;
    const msgBody = `Confirmez-vous le retrait de ce compte transporteur sur ce site logistique ?`;
    this.wcmModalsService.confirm(msgTitle, msgBody, 'Confirmer', 'Annuler').then(
      () => this._removeCarrierAccount(carrierAccount),
      () => {}
    );
  }

  private _removeCarrierAccount(item: ICarrierAccount): void {
    this.loading = true;
    // api call to remove the carrier account from the logistics site
    this.apiShiva.logistics_sites.removeCarrierAccount(this.detail.code, item.code)
      .then((): void => {
        this.signalsService.broadcast('carrier-accounts-list-refresh');
        this.signalsService.broadcast('model-history-list-refresh');
        this.toastr.success(`Compte transporteur retiré du site logistique`);
      })
      .catch((err: unknown): void => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'INEXISTING_CARRIER_ACCOUNT_CODES') {
            this.toastr.error(`Le compte transporteur (${item.code}) n'a pas été trouvé pour ce site logistique.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);
  }

  protected _fetch() {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res: ILogisticSite) => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs(res);
      })
      .catch(() => {})
      .finally(() => this.loading = false);
  }

  private _updateBreadcrumbs() {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.id) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/logistics-sites/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _initTabs(detail: ILogisticSite): void {
    this.carrierAccountsList = {
      filters: {
        logistics_site__code: detail.code,
      },
      disabledColumns: {
        action: false,
      },
      disabledButtons: {
        create: true,
        select: false,
      },
    };
  }
}
