import { Component, Injector, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CONFIG_TEMPLATE_TYPES, MERAKI_CONFIG_TEMPLATE_TYPES } from '@core/constants';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { omit } from '@core/helpers';
import { SignalsService } from '@core/services/signals.service';


@Component({
  selector: 'app-network-devices-list',
  templateUrl: './network-devices-list.component.html',
  styles: []
})
export class NetworkDevicesListComponent extends GenericListComponent {
  @Input() public source = '';

  public typeOptions: any;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,

  ) {
    super(injector);
    this.typeOptions = {...CONFIG_TEMPLATE_TYPES, ...MERAKI_CONFIG_TEMPLATE_TYPES};
    this.localDisabledButtons = {create: true, import: false};
    this.localDisabledColumns = {
      is_active: true, status: true, entity__parent__code_or_name: true,
      operator_line__offer__type: true
    };
    this.localFilters = {is_active: true};
  }

  public showImportNetworkDevicesModal() {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiProvitool.network_devices.network_devices_from_xlsx_import_url();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des équipements réseau';
  }

  public showImportMerakiNetworkDevicesModal() {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiProvitool.network_devices.meraki_network_devices_from_xlsx_import_url();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des équipements réseau Meraki';
  }

  public showImportMerakiMGNetworkDevicesModal() {
    const modal = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiProvitool.network_devices.meraki_mg_network_devices_from_xlsx_import_url();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des équipements réseau Meraki MG';
  }

  public downloadTemplate(name) {
    this.apiShiva.templates.detailByName(name)
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }
  public filteredExport(tableFilters) {
    const filters = omit(tableFilters, 'limit', 'offset', 'ordering', 'serializer');
    const taskName = 'Export des ips wan.';

    this.apiProvitool.network_devices.export(filters)
      .then((res) => {
        // When the server response is received and it's a success,
        this.signalsService.broadcastJobStart(taskName, res.job_id);
        this.toastr.success('Demande prise en compte. Veuillez patienter le temps que le fichier d\'export soit généré.');
      }).catch((err) => {
        this.toastr.error('Echec de la demande d\'export. Veuillez réessayer.');
      });
  }
}
