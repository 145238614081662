import { Component, Input, OnInit, OnChanges, OnDestroy, ViewChild, TemplateRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { isArray } from '@core/helpers';
import { IOperatorLine, ITinyEntityWithLocation, IWorkOrderItems } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { WOI_GENERIC_LINE_ACTIONS } from '@core/constants';

// SFR specific interface
interface ISFRWorkOrderItem extends Omit<IWorkOrderItems, 'metadata'> {
  metadata?: ISfr;
}

interface ISfr {
  action?: IAction;
  order_ref?: string;
  discharge_codes?: IDischargeCode[];
  operator_line_id?: number;
  operator_line_code?: string;
  operator_line_label?: string;
}

interface IAction {
  value: string;
  label: string;
  ol_states: Array<string>;
}

interface IDischargeCode {
  code?: number;
  reason?: string;
}

@Component({
  selector: 'app-sfr-ftth-line-metadata',
  templateUrl: './sfr-ftth-line-metadata.component.html',
  styles: []
})
export class SfrFtthLineMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dischargeCodeModalContent', {static: true}) public dischargeCodeModalContent: TemplateRef<any>;

  @Input() public mode: 'normal' | 'edition' = 'normal';
  @Input() public woi: ISFRWorkOrderItem;
  @Input() public woiRefreshDetail: Function; // woi refresh method exposed to child metadata component

  public entity: ITinyEntityWithLocation | undefined;
  public loadingCode: boolean;
  public modalReason: string;
  public newDischargeCode: number;
  public operatorLine: IOperatorLine;
  public metadataName = 'sfr-ftth-line';
  public displayOlWarnColNode: boolean;
  public displayOlWarnVlan: boolean;
  public filteredActions = WOI_GENERIC_LINE_ACTIONS;

  private metadataBackup: ISfr;
  private subscriptions: Subscription[] = [];

  constructor(
    private apiShiva: ApiShivaService,
    private apiProvitool: ApiProvitoolService,
    private signalsService: SignalsService,
    private ngbModal: NgbModal,
    private toastr: ToastrService
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {} as ISfr;

    this.subscriptions.push(this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = JSON.parse(JSON.stringify(this.metadataBackup));
    }));
    this.subscriptions.push(this.signalsService.subscribe('sfr-ftth-metadata-refresh', () => this._init()));
    this.subscriptions.push(this.signalsService.subscribe('woi-entity-change', (newEntity: ITinyEntityWithLocation) => {
      this.entity = newEntity?.code ? newEntity : undefined;
    }));

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup = JSON.parse(JSON.stringify(this.woi.metadata));
    }
    // Set an action by default when we swich to edition mode for workflow managment
    if (currentMode === 'edition') {
      this.woi.metadata.action = this.woi.metadata.action || this.filteredActions[0];
      this._getActionsForOL(this.operatorLine);
    } else if (previousMode === 'edition') {
      this._init();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Display a modal asking for a reason first
   *  reset the previous reason and discharge code if any
   */
  public displayDischargeCodeModal(): void {
    this.modalReason = '';
    this.newDischargeCode = null;
    this.ngbModal.open(this.dischargeCodeModalContent, {backdrop: 'static'});
  }

  public addDischargeCode(reason: string): void {
    if (!reason) { return; }

    this.loadingCode = true;

    if (!isArray(this.woi.metadata.discharge_codes)) {
      this.woi.metadata.discharge_codes = [];
    }

    const generatedCode = this._generateDischargeCode(this.woi.metadata.discharge_codes);
    this.woi.metadata.discharge_codes.push({
      code: generatedCode,
      reason
    });

    this.apiShiva.work_order_items.update(this.woi.id, this.woi)
      .then(() => {
        this.signalsService.broadcast('model-history-list-refresh');
        this._addDischargeCodeUpdateComment(generatedCode, reason);
        this.newDischargeCode = generatedCode;
      })
      .catch(() => {
        // removing the last added value because the save failed
        this.woi.metadata.discharge_codes.pop();
        this.toastr.error(`Erreur lors de l'enregistrement de la tâche. Veuillez essayer à nouveau.`);
      })
      .finally(() => this.loadingCode = false);
  }

  public operatorLineUpdated() {
    this.displayOlWarnColNode = false;
    this.displayOlWarnVlan = false;

    this.woi.metadata.operator_line_code = this.operatorLine && this.operatorLine.code;
    this.woi.metadata.operator_line_label = this.operatorLine && this.operatorLine.label;

    this.filteredActions = this._getActionsForOL(this.operatorLine);

    if (this.operatorLine) {
      this.woi.metadata.action = this.filteredActions[0];
    }else {
      this.woi.metadata.action = null;
    }
  }

  public compareActionFn(obj1, obj2) {
    return obj1 && obj2 ? obj1.value === obj2.value : obj1 === obj2;
  }

  private _init(): void {
    this.entity = this.woi?.work_order?.entity;
    this._initOperatorLine();
  }

  private _checkOLInfo(): void {
    const offerHasColNode = this.operatorLine.offer?.has_collection_nodes;
    this.displayOlWarnColNode = (this.operatorLine && !this.operatorLine.collection_node && offerHasColNode) ? true : false;
    this.displayOlWarnVlan = (this.operatorLine && !this.operatorLine.vlan && offerHasColNode) ? true : false;
  }

  private _initOperatorLine(): void {
    const operatorLineCode = this.woi?.metadata?.operator_line_code;
    if (operatorLineCode) {
      this._fetchOperatorLine(operatorLineCode);
    } else {
      this.operatorLine = null;
    }
  }

  private _fetchOperatorLine(operatorLineCode: string): void {
    this.apiProvitool.operator_lines.detail(operatorLineCode).then(
      res => {
        this.operatorLine = res;
        // OL has changed, need to re-filter the available actions
        this.filteredActions = this._getActionsForOL(this.operatorLine);
        if (this.filteredActions.length === 1) { this.woi.metadata.action = this.filteredActions[0]; }
        this._checkOLInfo();
      },
      err => {
        console.error(err);
        this.toastr.error('Echec de récupération du lien opérateur.');
      }
    );
  }

  private _getActionsForOL(ol) {
    if (!ol || !ol.state) {
      return WOI_GENERIC_LINE_ACTIONS;
    }

    const isOldActionValueKept = ol.state?.name !== 'delivered' ? true : false;

    const availableActions = WOI_GENERIC_LINE_ACTIONS.filter((action) =>  {
      return action.ol_states.indexOf(ol.state.name) > -1;
    });

    if (this.woi.metadata.action && Object.keys(this.woi.metadata.action).length) {
      const isCurrentActionInAvailableList = availableActions.find(i => i.value === this.woi.metadata.action.value) ? true : false;
      if (!isCurrentActionInAvailableList && isOldActionValueKept) {
        availableActions.push(this.woi.metadata.action);
      }
    }
    return availableActions;
  }

  private _generateDischargeCode(existingDischargeCodes: IDischargeCode[]): number {
    // random number between 100 000 and 999 9999
    const min = 100000;
    const max = 999999;
    const generatedCode = Math.floor(Math.random() * (max - min + 1) + min);

    const existingCodes = existingDischargeCodes.map((existing: IDischargeCode) => existing.code);
    if (existingCodes.includes(generatedCode)) {
      // the code is already in use, we do a recursive call
      return this._generateDischargeCode(existingDischargeCodes);
    } else {
      return generatedCode;
    }
  }

  private _addDischargeCodeUpdateComment(newDischargeCode: number, reason: string): void {
    const commentsApi = this.apiShiva.comments('work-order-item', this.woi.id);
    const comment = `Génération du code de décharge **${newDischargeCode}**.\nRaison : ${reason}`;
    commentsApi.create(comment)
      .then(() => this.signalsService.broadcast('comments:update'))
      .catch(() => this.toastr.error(`Erreur lors de l'ajout du commentaire de génération du code de décharge.`))
      .finally(() => this.signalsService.broadcast('woi-detail:refresh'));
  }

}
