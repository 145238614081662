<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">
        <button *hasPermissions="'Antoine:LogisticsSiteAdmin'" type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                [disabled]="f.invalid || loading">
          Créer
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                [disabled]="f.invalid || loading">
          Enregistrer
        </button>
        <button type="button" class="btn btn-default" (click)="cancel()" *ngIf="detail.id">
          Annuler
        </button>
      </div>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>
    <div class="form-group">
      <ng-container *ngIf="detail?.id">
        <label class="col-sm-2 control-label">
          Code
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">
            {{ detail.code }}
          </p>
        </div>
      </ng-container>

      <label class="col-sm-2 control-label">
        Nom <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req" name="name"
               [required]="true"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.name" />
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse
      </label>
      <div class="col-sm-4">
        <app-location-field [(ngModel)]="detail.location" name="location"
                            [disabled]="mode === 'normal'"
                            [filters]="{ type: 'shipping' }">
        </app-location-field>
      </div>

      <label class="col-sm-2 control-label">
        Contact
      </label>
      <div class="col-sm-4">
        <input class="form-control" name="site_contact"
               [readonly]="mode === 'normal'"
               [(ngModel)]="detail.site_contact">
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Libellé
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{ detail.inline_address }}
        </p>
      </div>
      <label class="col-sm-2 control-label">
        Téléphone
      </label>
      <div class="col-sm-4">
        <app-phone-number-field name="phone"
                                [regionCode]="detail.location?.country"
                                [disabled]="mode === 'normal'"
                                [(ngModel)]="detail.phone">
        </app-phone-number-field>
      </div>
    </div>

  </form>

  <ng-container *ngIf="detail?.id && !hideTabs">
    <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills"
        [destroyOnHide]="false"
        [activeId]="activeTab"
        (activeIdChange)="onTabChange($event)">

      <li [ngbNavItem]="0">
        <a ngbNavLink>Emplacement</a>
        <ng-template ngbNavContent>
          <app-equipment-location-list [logisticsSite]="detail"></app-equipment-location-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="1" *hasPermissions="'Antoine:CarrierAccountCanDisplay'">
        <a ngbNavLink>Comptes transporteurs</a>
        <ng-template ngbNavContent>
          <app-carrier-accounts-list *ngIf="tabsStatus[1]"
                                     [disableUrlUpdate]="true"
                                     [disabledButtons]="carrierAccountsList.disabledButtons"
                                     [disabledColumns]="carrierAccountsList.disabledColumns"
                                     [filters]="carrierAccountsList.filters"
                                     [logisticsSite]="detail"
                                     (removeItem)="removeCarrierAccount($event)"
                                     (selectItems)="addCarrierAccounts()">
          </app-carrier-accounts-list>
        </ng-template>
      </li>

      <li [ngbNavItem]="2">
        <a ngbNavLink>Historique</a>
        <ng-template ngbNavContent>
          <app-model-histories-list *ngIf="tabsStatus[2]"
                                    model="logisticssite" id="{{ detail.id }}"
                                    [createdBy]="detail.created_by"
                                    [createdAt]="detail.created_at">
          </app-model-histories-list>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav"></div>
  </ng-container>
</div>
