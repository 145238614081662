<form #f="ngForm" class="wcm-erp modal-view" (ngSubmit)="addEquipmentLocation()">
  <!-- this submit button is here to trigger the submit when the user presses the enter key -->
  <!-- the form (ngSubmit) function will be in charge of handling the call -->
  <button type="submit" [hidden]="true"></button>
  <app-wcm-table #wcmTable
                 refreshSignal="equipment-locations-list-refresh"
                 urlUpdateSignal="equipment-locations-list-url-update"
                 [api]="api"
                 [filters]="localFilters"
                 [disabledColumns]="localDisabledColumns"
                 [disableUrlUpdate]="disableUrlUpdate"
                 [allowPreferences]="false"
                 [hideColumnsWheel]="true"
                 [hidePaginator]="false"
                 [disableFiltersDisplay]="true"
                 [enableRowClick]="enableRowClick"
                 (rowClick)="rowClick?.emit($event)"
                 (fetchCallback)="fetchCallback()">
    <ng-template appWcmHeaderTitle text="Emplacements"></ng-template>
    <ng-template appWcmHeaderRight>
      <button class="btn btn-default"
              type="button"
              (click)="addEquipmentLocation()"
              [disabled]="editionInProgress">
        <i class="fa fa-plus"></i> Nouveau
      </button>
    </ng-template>

    <app-wcm-col field="number" header="Numéro" type="none" class="text-center" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <input class="form-control wcm-highlight-req"
               name="number_{{ item.id }}"
               maxlength="50"
               [disabled]="!item.editable"
               [required]="true"
               [(ngModel)]="item.number" />
      </ng-template>
    </app-wcm-col>

    <app-wcm-col field="warehouse" header="Entrepôt" type="none" class="text-center" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <select class="form-control wcm-highlight-req"
                name="warehouse_{{ item.id }}"
                [disabled]="!item.editable"
                [required]="true"
                [(ngModel)]="item.warehouse">
          <option *ngFor="let opt of warehouses | keyvalue" [value]="opt.key">{{ opt.value }}</option>
        </select>
      </ng-template>
    </app-wcm-col>

    <app-wcm-col header="Actions" id="actions" type="none" width="100" class="text-right" [preventSort]="true">
      <ng-template appWcmColBody let-item="item">
        <div class="action-btn-bar">
          <button *ngIf="!item.editable && !editionInProgress"
                  class="btn btn-default btn-xs" title="Editer" type="button"
                  [disabled]="loadingItemAction"
                  (click)="edit(item)">
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button *ngIf="!item.editable && !editionInProgress"
                  class="btn btn-default btn-xs" title="Supprimer" type="button"
                  [disabled]="loadingItemAction"
                  (click)="confirmDelete(item)">
            <i class="fa fa-trash-alt"></i>
          </button>
          <button *ngIf="item.editable"
                  class="btn btn-default btn-xs" title="Enregistrer" type="submit"
                  [disabled]="loadingItemAction || f.form.invalid"
                  (click)="save(item)">
            <i class="fas fa-check"></i>
          </button>
          <button *ngIf="item.editable"
                  class="btn btn-default btn-xs" title="Annuler" type="button"
                  [disabled]="loadingItemAction"
                  (click)="cancelEdit(item)">
            <i class="fas fa-undo"></i>
          </button>
        </div>
      </ng-template>
    </app-wcm-col>
  </app-wcm-table>
</form>
