import { Routes } from '@angular/router';

import { GenericRouteCanDeactivateGuard } from '@core/globals/generic-route/generic-route-can-deactivate-guard.service';
import { GenericRouteCanActivateGuard } from '@core/globals/generic-route/generic-route-can-activate-guard.service';
import { LinkCanActivateGuard } from './link-can-activate-guard.service';

import { DashboardComponent } from '@views/dashboard/dashboard.component';
import { EntitiesRouteComponent } from '@views/entities/entities-route.component';
import { EsSearchComponent } from '@views/es-search/es-search.component';
import { NotFoundComponent } from '@views/not-found/not-found.component';
import { OrdersRouteComponent } from '@views/orders/orders-route.component';
import { PermissionsComponent } from '@views/permissions/permissions.component';
import { LogisticsSitesRoutingModule } from '@views/logistics-sites/logistics-sites-routing.module';


// Because of the AOT, this variable cannot have any dynamic content
// or be built from other variables
// We declare the routes two times to have the optional parameter pk
export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {title: 'Accueil'}
  },
  {
    path: 'disabled-entities/:view',
    component: EntitiesRouteComponent,
    data: {title: 'Entités désactivées', disabledMode: true}
  },
  {
    path: 'es-search',
    component: EsSearchComponent,
    data: {title: 'Recherche'}
  },
  {
    path: 'orders-mrp/:view/:pk',
    component: OrdersRouteComponent,
    data: {title: 'Commandes', financeMode: false},
    canDeactivate: [GenericRouteCanDeactivateGuard],
    canActivate: [GenericRouteCanActivateGuard]
  },
  {
    path: 'orders-mrp/:view',
    component: OrdersRouteComponent,
    data: {title: 'Commandes', financeMode: false}
  },
  {
    path: 'accounting-equipments',
    loadChildren: () => import('@views/accounting-equipments/accounting-equipments-routing.module').then(m => m.AccountingEquipmentsRoutingModule),
  },
  {
    path: 'accounts',
    loadChildren: () => import('@views/accounts/accounts-routing.module').then(m => m.AccountsRoutingModule),
  },
  {
    path: 'argos-event-mutes',
    loadChildren: () => import('@views/argos-event-mutes/argos-event-mutes-routing.module').then(m => m.ArgosEventMutesRoutingModule),
  },
  {
    path: 'argos-users',
    loadChildren: () => import('@views/argos-users/argos-users-routing.module').then(m => m.ArgosUsersRoutingModule),
  },
  {
    path: 'backbone-equipments',
    loadChildren: () => import('@views/backbone-equipments/backbone-equipments-routing.module').then(m => m.BackboneEquipmentsRoutingModule),
  },
  {
    path: 'bill-of-materials',
    loadChildren: () => import('@views/bill-of-materials/bill-of-materials-routing.module').then(m => m.BillOfMaterialsRoutingModule),
  },
  {
    path: 'billing-uploads',
    loadChildren: () => import('@views/billing-uploads/billing-uploads-routing.module').then(m => m.BillingUploadsRoutingModule),
  },
  {
    path: 'binders',
    loadChildren: () => import('@views/binders/binders-routing.module').then(m => m.BindersRoutingModule),
  },
  {
    path: 'collection-nodes',
    loadChildren: () => import('@views/collection-nodes/collection-nodes-routing.module').then(m => m.CollectionNodesRoutingModule),
  },
  {
    path: 'companies',
    loadChildren: () => import('@views/companies/companies-routing.module').then(m => m.CompaniesRoutingModule),
  },
  {
    path: 'config-templates',
    loadChildren: () => import('@views/config-templates/config-templates-routing.module').then(m => m.ConfigTemplatesRoutingModule),
  },
  {
    path: 'configurable-menus',
    loadChildren: () => import('@views/configurable-menus/configurable-menus-routing.module').then(m => m.ConfigurableMenusRoutingModule),
  },
  {
    path: 'consoles',
    loadChildren: () => import('@views/consoles/consoles-routing.module').then(m => m.ConsolesRoutingModule),
  },
  {
    path: 'carrier-accounts',
    loadChildren: () => import('@views/carrier-accounts/carrier-accounts-routing.module').then(m => m.CarrierAccountsRoutingModule),
  },
  {
    path: 'contacts',
    loadChildren: () => import('@views/contacts/contacts-routing.module').then(m => m.ContactsRoutingModule),
  },
  {
    path: 'contractors',
    loadChildren: () => import('@views/contractors/contractors-routing.module').then(m => m.ContractorsRoutingModule),
  },
  {
    path: 'control-files',
    loadChildren: () => import('@views/control-files/control-files-routing.module').then(m => m.ControlFilesRoutingModule),
  },
  {
    path: 'currencies',
    loadChildren: () => import('@views/currencies/currencies-routing.module').then(m => m.CurrenciesRoutingModule),
  },
  {
    path: 'waiting',
    loadChildren: () => import('@views/waiting/waiting-routing.module').then(m => m.WaitingRoutingModule),
  },
  {
    path: 'eligibility',
    loadChildren: () => import('@views/eligibility/eligibility.module').then(m => m.EligibilityModule),
  },
  {
    path: 'entities',
    loadChildren: () => import('@views/entities/entities-routing.module').then(m => m.EntitiesRoutingModule),
  },
  {
    path: 'entity-bulk-imports',
    loadChildren: () => import('@views/entity-bulk-imports/entity-bulk-imports-routing.module').then(m => m.EntityBulkImportsRoutingModule),
  },
  {
    path: 'entities-merge',
    loadChildren: () => import('@views/entities-merge/entities-merge.module').then(m => m.EntitiesMergeModule),
  },
  {
    path: 'entity-types',
    loadChildren: () => import('@views/entity-types/entity-types-routing.module').then(m => m.EntityTypesRoutingModule),
  },
  {
    path: 'equipment-constructors',
    loadChildren: () => import('@views/equipment-constructors/equipment-constructors-routing.module').then(m => m.EquipmentConstructorsRoutingModule),
  },
  {
    path: 'equipment-models',
    loadChildren: () => import('@views/equipment-models/equipment-models-routing.module').then(m => m.EquipmentModelsRoutingModule),
  },
  {
    path: 'equipment-models-merge',
    loadChildren: () => import('@views/equipment-models-merge/equipment-models-merge.module').then(m => m.EquipmentModelsMergeModule),
  },
  {
    path: 'equipments',
    loadChildren: () => import('@views/equipments/equipments-routing.module').then(m => m.EquipmentsRoutingModule),
  },
  {
    path: 'fiscal-positions',
    loadChildren: () => import('@views/fiscal-positions/fiscal-positions-routing.module').then(m => m.FiscalPositionsRoutingModule),
  },
  {
    path: 'invoice-archives',
    loadChildren: () => import('@views/invoice-archives/invoice-archives-routing.module').then(m => m.InvoiceArchivesRoutingModule),
  },
  {
    path: 'invoice-sage-exports',
    loadChildren: () => import('@views/invoice-sage-exports/invoice-sage-exports-routing.module').then(m => m.InvoiceSageExportsRoutingModule),
  },
  {
    path: 'invoice-send-items',
    loadChildren: () => import('@views/invoice-send-items/invoice-send-items-routing.module').then(m => m.InvoiceSendItemsRoutingModule),
  },
  {
    path: 'invoice-send-events',
    loadChildren: () => import('@views/invoice-send-events/invoice-send-events-routing.module').then(m => m.InvoiceSendEventsRoutingModule),
  },
  {
    path: 'invoice-transmissions',
    loadChildren: () => import('@views/invoice-transmissions/invoice-transmissions-routing.module').then(m => m.InvoiceTransmissionsRoutingModule),
  },
  {
    path: 'invoices',
    loadChildren: () => import('@views/invoices/invoices-routing.module').then(m => m.InvoicesRoutingModule),
  },
  {
    path: 'lines',
    loadChildren: () => import('@views/lines/lines-routing.module').then(m => m.LinesRoutingModule),
  },
  {
    path: 'locations',
    loadChildren: () => import('@views/locations/locations-routing.module').then(m => m.LocationsRoutingModule),
  },
  {
    path: 'logistics-requests',
    loadChildren: () => import('@views/logistics-requests/logistics-requests-routing.module').then(m => m.LogisticsRequestsRoutingModule),
  },
  {
    path: 'logistics-sites',
    loadChildren: () => import('@views/logistics-sites/logistics-sites-routing.module').then(m => m.LogisticsSitesRoutingModule),
  },
  // TODO Remove all the meraki views created for dev purpose
  {
    path: 'meraki-config-templates',
    loadChildren: () => import('@views/meraki-config-templates/meraki-config-templates-routing.module').then(m => m.MerakiConfigTemplatesRoutingModule),
  },
  {
    path: 'meraki-networks',
    loadChildren: () => import('@views/meraki-networks/meraki-networks-routing.module').then(m => m.MerakiNetworksRoutingModule),
  },
  {
    path: 'meraki-organizations',
    loadChildren: () => import('@views/meraki-organizations/meraki-organizations-routing.module').then(m => m.MerakiOrganizationsRoutingModule),
  },
  {
    path: 'meraki-switch-profiles',
    loadChildren: () => import('@views/meraki-switch-profiles/meraki-switch-profiles-routing.module').then(m => m.MerakiSwitchProfilesRoutingModule),
  },
  {
    path: 'network-devices',
    loadChildren: () => import('@views/network-devices/network-devices-routing.module').then(m => m.NetworkDevicesRoutingModule),
  },
  {
    path: 'offers',
    loadChildren: () => import('@views/offers/offers-routing.module').then(m => m.OffersRoutingModule),
  },
  {
    path: 'operator-lines',
    loadChildren: () => import('@views/operator-lines/operator-lines-routing.module').then(m => m.OperatorLinesRoutingModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('@views/orders/orders-routing.module').then(m => m.OrdersRoutingModule),
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('@views/payment-methods/payment-methods-routing.module').then(m => m.PaymentMethodsRoutingModule),
  },
  {
    path: 'payment-terms',
    loadChildren: () => import('@views/payment-terms/payment-terms-routing.module').then(m => m.PaymentTermsRoutingModule),
  },
  {
    path: 'personal-menus',
    loadChildren: () => import('@views/personal-menus/personal-menus-routing.module').then(m => m.PersonalMenusRoutingModule),
  },
  {
    path: 'price-books',
    loadChildren: () => import('@views/price-books/price-books-routing.module').then(m => m.PriceBooksRoutingModule),
  },
  {
    path: 'products',
    loadChildren: () => import('@views/products/products-routing.module').then(m => m.ProductsRoutingModule),
  },
  {
    path: 'product-categories',
    loadChildren: () => import('@views/product-categories/product-categories-routing.module').then(m => m.ProductCategoriesRoutingModule),
  },
  {
    path: 'project-items',
    loadChildren: () => import('@views/project-items/project-items-routing.module').then(m => m.ProjectItemsRoutingModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('@views/projects/projects-routing.module').then(m => m.ProjectsRoutingModule),
  },
  {
    path: 'provi-histories',
    loadChildren: () => import('@views/provi-histories/provi-histories-routing.module').then(m => m.ProviHistoriesRoutingModule),
  },
  {
    path: 'providers',
    loadChildren: () => import('@views/providers/providers-routing.module').then(m => m.ProvidersRoutingModule),
  },
  {
    path: 'provider-invoices',
    loadChildren: () => import('@views/provider-invoices/provider-invoices-routing.module').then(m => m.ProviderInvoicesRoutingModule),
  },
  {
    path: 'provider-orders',
    loadChildren: () => import('@views/provider-orders/provider-orders-routing.module').then(m => m.ProviderOrdersRoutingModule),
  },
  {
    path: 'provider-traceabilities',
    loadChildren: () => import('@views/provider-traceabilities/provider-traceabilities-routing.module').then(m => m.ProviderTraceabilitiesRoutingModule),
  },
  {
    path: 'quotes',
    loadChildren: () => import('@views/quotes/quotes-routing.module').then(m => m.QuotesRoutingModule),
  },
  {
    path: 'refund-sage-exports',
    loadChildren: () => import('@views/refund-sage-exports/refund-sage-exports-routing.module').then(m => m.RefundSageExportsRoutingModule),
  },
  {
    path: 'sdas',
    loadChildren: () => import('@views/sdas/sdas-routing.module').then(m => m.SdasRoutingModule),
  },
  {
    path: 'sequences',
    loadChildren: () => import('@views/sequences/sequences-routing.module').then(m => m.SequencesRoutingModule),
  },
  {
    path: 'tags',
    loadChildren: () => import('@views/tags/tags-routing.module').then(m => m.TagsRoutingModule),
  },
  {
    path: 'taxes',
    loadChildren: () => import('@views/taxes/taxes-routing.module').then(m => m.TaxesRoutingModule),
  },
  {
    path: 'technicians',
    loadChildren: () => import('@views/technicians/technicians-routing.module').then(m => m.TechniciansRoutingModule),
  },
  {
    path: 'technologies',
    loadChildren: () => import('@views/technologies/technologies-routing.module').then(m => m.TechnologiesRoutingModule),
  },
  {
    path: 'template-emails',
    loadChildren: () => import('@views/template-emails/template-emails-routing.module').then(m => m.TemplateEmailsRoutingModule),
  },
  {
    path: 'templates',
    loadChildren: () => import('@views/templates/templates-routing.module').then(m => m.TemplatesRoutingModule),
  },
  {
    path: 'traceabilities',
    loadChildren: () => import('@views/traceabilities/traceabilities-routing.module').then(m => m.TraceabilitiesRoutingModule),
  },
  {
    path: 'vrfs',
    loadChildren: () => import('@views/vrfs/vrfs-routing.module').then(m => m.VrfsRoutingModule),
  },
  {
    path: 'work-order-items',
    loadChildren: () => import('@views/work-order-items/work-order-items-routing.module').then(m => m.WorkOrderItemsRoutingModule),
  },
  {
    path: 'work-orders',
    loadChildren: () => import('@views/work-orders/work-orders-routing.module').then(m => m.WorkOrdersRoutingModule),
  },
  {
    path: 'link/:pk',
    canActivate: [LinkCanActivateGuard],
    component: DashboardComponent
  },
  {
    path: 'permissions',
    component: PermissionsComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'unauth',
    redirectTo: 'dashboard'
  },
  {
    path: '**',
    redirectTo: '404'
  },
];
