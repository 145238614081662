<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <ng-container *ngIf="hasPermissions('Provitool:NetworkAdmin')">
        <div *ngIf="mode === 'normal'">
          <button type="button" class="btn btn-default" (click)="edit()">
            Modifier
          </button>
        </div>
        <div *ngIf="mode === 'edition'">
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!detail.id"
                  [disabled]="f.invalid || loading">
            Créer
          </button>
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="detail.id"
                  [disabled]="f.invalid || loading">
            Enregistrer
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()"z>
            Annuler
          </button>
        </div>
      </ng-container>
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <div class="form-group">
      <label class="col-sm-4 control-label">
        Subnet <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-vrf-lan-subnet-field class="wcm-highlight-req"
               name="lan_subnet" [required]="true"
               [filters]="lanSubnet.filters"
               [disabledColumns]="lanSubnet.disabledColumns"
               [networkDevice]="detail.network_device"
               [disabled]="mode === 'normal'"
               [(ngModel)]="detail.lan_subnet"
               (ngModelChange)="subnetUpdated($event)">
        </app-vrf-lan-subnet-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">
        IP équipement réseau <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="address" [required]="true"
               [readonly]="mode === 'normal' || !detail.lan_subnet"
               [pattern]="ipPattern"
               [appForbiddenValues]="IPsInUse"
               [(ngModel)]="detail.address"
               (ngModelChange)="addressUpdated($event)" />
        <div class="error-message" *ngIf="f.controls.address?.errors?.pattern">
          Le format de l'IP est incorrect.<br>Exemple de valeur attendue : 192.168.1.5
        </div>
        <div class="error-message" *ngIf="f.controls.address?.errors?.forbiddenValues">
          {{reservedIPError ? 'La première et la dernière IP du subnet sont réservées.' : 'L\'IP est déjà utilisée pour ce subnet.' }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label">
        IP déjà utilisées dans le subnet
      </label>
      <div class="col-sm-4">
        <div class="form-control-static">
          <div *ngFor="let subnetLanIp of detail?.lan_subnet?.vrf_lan_subnet_ips">
            {{subnetLanIp.address}}
            (Type : {{deviceTypes[subnetLanIp.network_device.type] || subnetLanIp.network_device.type}},
             Numéro : {{subnetLanIp.network_device.number}}
            )
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
