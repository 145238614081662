import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '@core/core.module';
import { CarrierAccountsModule } from '@views/carrier-accounts/carrier-accounts.module';
import { ModelHistoriesModule } from '@views/model-histories/model-histories.module';
import { EquipmentLocationListComponent } from './equipment-location-list.component';
import { LogisticsSitesListComponent } from './logistics-sites-list.component';
import { LogisticsSitesDetailComponent } from './logistics-sites-detail.component';
import { LogisticsSitesRouteComponent } from './logistics-sites-route.component';
import { LogisticsSiteModalComponent } from './logistics-site-modal.component';
import { LogisticsSiteFieldComponent } from './logistics-site-field.component';
import { GenericFieldModule } from '@app/core/globals/generic-field/generic-field.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ModelHistoriesModule,
    CarrierAccountsModule,
    GenericFieldModule,
  ],
  declarations: [
    EquipmentLocationListComponent,
    LogisticsSitesListComponent,
    LogisticsSitesDetailComponent,
    LogisticsSitesRouteComponent,
    LogisticsSiteModalComponent,
    LogisticsSiteFieldComponent,
  ],
  exports: [
    LogisticsSitesListComponent,
    LogisticsSitesDetailComponent,
    LogisticsSiteFieldComponent,
  ],
})
export class LogisticsSitesModule {}
